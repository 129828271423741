<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="row justify-content-between align-items-center mb-4">
          </div>
          <base-table v-if="!isSpinnerShow" :data="logs" :columns="logsColumns" :columnsSize="columnsSize"
            class="col table-responsive-sm">
            <template slot="columns">
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("bookingInfo.dateAndTime") }}</th>
              <th scope="col"></th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.user_name }}</td>
              <td>{{ row.created_at }}</td>
              <td>{{ row.action_name }}</td>
            </template>
          </base-table>
          <base-table v-else-if="logs.length === 0" :data="[]" class="table-responsive-md">
            <template slot="columns">
              <th scope="col">{{ $t("idUser") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("bookingInfo.dateAndTime") }}</th>
              <th scope="col"></th>
            </template>
            <template>
              <td class="col-3"></td>
              <td class="col-3"></td>
              <td class="col-3">
                <Spinner parentClasses="justify-content-start"></Spinner>
              </td>
              <td class="col-3"></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination v-if="logs.length > 0" @turnPage="getItems" element="routes.employeeList"
              :perPage="pagination.perPage" :lastPage="pagination.lastPage" :totalItems="pagination.totalItems"
              :isChanging="pagination.isChanging" :currentPage="currentPage"></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable, Card, Pagination, Spinner } from "@/components/index";

export default {
  name: "LogsEmployeesList",
  data() {
    return {
      route: `/stores`,
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      logs: [],
      logsColumns: ["name", "actions"],
      columnsSize: [null, null, null],
      isSpinnerShow: true,
      isPopoverVisible: false,
      isModalShow: false,
      currentPage: 1,
      storeId: null,
    };
  },
  methods: {
    async getItems(page = 1) {

      this.currentPage = page;
      this.pagination.isChanging = true;
      const route = `${this.route}/${this.storeId}/employees-log?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data;
        this.logs = data.data;

        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.logs.length === 0;
      }
      this.pagination.isChanging = false;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.storeId = (user.store) ? user.store.id : this.$route.params.id;
    this.getItems();
  },
  components: {
    BaseTable,
    Card,
    Pagination,
    Spinner
  },
};
</script>

<style>
.minHeight {
  min-height: 90vh !important;
}
</style>
