<template>
  <div class="content">
    <tabs >
      <tab :name="$tc('routes.employeeList', 2)" :selected="true">
        <div class="row">
          <div class="col-12">
            <card class="minHeight">
              <div class="d-block pb-2 d-sm-flex justify-content-sm-end">
                <div>
                  <Popover
                      trigger="manual"
                      placement="left"
                      :content="$t('clickAddEmployee')"
                      popper-class="font-weight-bold shadow-popover'"
                      v-model="isPopoverVisible"
                  >
                    <base-button
                      slot="reference"
                      v-if="isManager"
                      type="primary"
                      class="mb-4"
                      @click="$router.push({ name: 'newEmployee' })"
                      >{{ $tc("new") }}</base-button
                    >
                  </Popover>
                </div>
              </div>

              <div class="warning-container">
                <span v-if="areEmployeeWithoutServices">
                  <svg class="mx-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                    <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                    {{ $t('employeeWithoutServices') }}
                </span>
              </div>

              <base-table
                v-if="!isSpinnerShow"
                :data="employees"
                :columns="employeesColumns"
                :columnsSize="columnsSize"
                @clickRow="goToDetailView"
                class="table-responsive-md"
                aria-describedby="description"
              >
                <template slot="columns">
                  <th scope="col">{{ $t("name") }}</th>
                  <th scope="col">{{ $t("email") }}</th>
                  <th scope="col">{{ $t("gender") }}</th>
                  <th scope="col">{{ $t("phone") }}</th>
                  <th scope="col">{{ $t("user_last_login") }}</th>
                </template>
                <template slot-scope="{ row }">
                  <td>
                    {{ row.name }}
                    <svg
                      v-show="row.services?.length === 0"
                      class="ml-1 warning-icon"
                      width="15px" height="15px" stroke-width="1.5"
                      viewBox="0 0 24 24" fill="none" color="#000000">
                        <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </td>
                  <td>{{ row.email }}</td>
                  <td>{{ $t(row.gender) }}</td>
                  <td>{{ $t(row.phone) }}</td>
                  <td>{{ row.user_last_login }}</td>
                </template>
              </base-table>
              <base-table
                  v-else-if="employees.length === 0"
                  :data="[1]"
                  class="table-responsive-md"
              >
                <template slot="columns">
                  <th scope="col">{{ $t("idEmployee") }}</th>
                  <th scope="col">{{ $t("name") }}</th>
                  <th scope="col">{{ $t("email") }}</th>
                  <th scope="col">{{ $t("gender") }}</th>
                  <th scope="col">{{ $t("phone") }}</th>
                  <th scope="col">{{ $t("user_last_login") }}</th>
                </template>
                <template>
                  <td class="col-2"></td>
                  <td class="col-2"></td>
                  <td class="col-2"><Spinner parentClasses="justify-content-center"></Spinner></td>
                  <td class="col-2"></td>
                  <td class="col-2"></td>
                </template>
              </base-table>
              <template slot="footer">
                <pagination
                  v-if="employees.length > 0"
                  @turnPage="getItems"
                  element="routes.employeeList"
                  :perPage="pagination.perPage"
                  :lastPage="pagination.lastPage"
                  :totalItems="pagination.totalItems"
                  :isChanging="pagination.isChanging"
                  :currentPage="currentPage"
                ></pagination>
              </template>
            </card>
          </div>
        </div>
      </tab>
    <tab :name="$tc('registerEmployees', 2)">
      <EmployeesLogs />
    </tab>
  </tabs>

  </div>
</template>

<script>
import { Tabs, Tab } from "../../components";
import { Card, BaseTable, Pagination, Spinner } from "@/components/index";
import EmployeesLogs from "./EmployeesLogs";
import { mapState } from "vuex";
import { Popover } from "element-ui";

export default {
  name: "EmployeeList",
  data() {
    return {
      route: "/employees",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      employees: [],
      employeesColumns: ["name", "email", "gender","phone","user_last_login"],
      columnsSize: [null, null, null, null, null],
      isSpinnerShow: true,
      isPopoverVisible: false,
      currentPage: 1,
      test: false
    };
  },
  computed: {
    areEmployeeWithoutServices() {
      return this.employees.some( employee => employee.services?.length === 0 );
    },
    ...mapState(["isManager", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.currentPage = page;
      this.pagination.isChanging = true;
      const route = `${this.route}?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.employees;
        this.employees = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.employees.length === 0;
      }
      this.pagination.isChanging = false;
    },
    goToDetailView(itemId) {
      this.$router.push({ name: "employeeManagement", params: { id: itemId } });
    },
  },
  created() {
    if (!this.isManager) this.$router.push("/");
  },
  mounted() {
    this.getItems();
  },
  metaInfo() {
    return { title: `${this.$tc('employee', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseTable,
    Pagination,
    Spinner,
    Popover,
    Tabs,
    Tab,
    EmployeesLogs
  },
};
</script>

<style scoped>
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
.warning-container {
  min-height: 21px;
  margin-bottom: 5px;
}
</style>
